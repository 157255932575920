import React from "react"
import { ThemeProvider } from "styled-components"

// import { StoreProvider } from "../../store"
import theme from "../../theme"

export default function RootElement({ children }) {
  return (
    // <StoreProvider>
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
    // </StoreProvider>
  )
}
