/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from "react"

import RootElement from "./src/components/root-element"

// wrapRootElement

export const wrapRootElement = ({ element }) => {
  return <RootElement>{element}</RootElement>
}

// onRouteUpdate

export function onRouteUpdate({ location, prevLocation }) {
  if (location && prevLocation) {
    const event = document.createEvent(`Event`)
    event.initEvent(`onRouteChange`, true, true)
    window.dispatchEvent(event)

    if (window.ga) {
      window.ga(`send`, `pageview`)
    }
  }
}
