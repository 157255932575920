export default {
  white: `#fff`,
  black: `#373b39`,

  grey: `#444946`,
  greyLight: `#707973`,
  greyLightest: `#eaefed`,

  green: `#3f8259`,
  greenDarker: `#164a2a`,

  yellow: `#c37d09`,
  yellowLight: `#f8c46c`,
  yellowLighter: `#fef3e1`,
  yellowDarker: `#615034`,

  red: `#8c2727`,
  bordo: `#783335`,

  fb: `#165eef`,
  fbLightest: `#eaeef7`,
}
